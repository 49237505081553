import React from "react";
import styled from "styled-components";

// Styled components for the download page
const DownloadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(245, 102, 7, 0.3);
`;

const Heading = styled.h2`
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
`;

const StepsList = styled.ol`
    list-style: decimal;
    padding: 0 20px;
    margin: 20px 0;
    text-align: left;
    font-size: 1em;
`;

const StepItem = styled.li`
    margin-bottom: 15px;
`;

const DownloadLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
    width: 100%;
    align-items: center;
`;

const LinkButton = styled.a`
    padding: 10px 15px;
    background-color: #f56607;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: #e55906;
    }
`;

const Note = styled.p`
    font-size: 0.9em;
    color: #666;
    margin-top: 20px;
    text-align: center;
`;

// Download Page Component
const DownloadPage: React.FC = () => {
    return (
        <DownloadContainer>
            <Heading>Stiahnite si našu aplikáciu</Heading>
            <StepsList>
                <StepItem>Kliknite na odkaz podľa vášho zariadenia (iOS alebo Android).</StepItem>
                <StepItem>Pre iOS budete presmerovaní do App Store, kde si aplikáciu môžete stiahnuť.</StepItem>
                <StepItem>Pre Android stiahnite súbor APK a nainštalujte ho. Uistite sa, že máte povolenú inštaláciu aplikácií z neznámych zdrojov.</StepItem>
            </StepsList>
            <DownloadLinks>
                <LinkButton
                    href="https://apps.apple.com/sk/app/g%C3%B3ly-es%C3%A1/id6736998594" // Replace with actual App Store URL
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Stiahnuť pre iOS (App Store)
                </LinkButton>
                <LinkButton
                    href="/GolyEsa.apk" // Replace with actual APK URL
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Stiahnuť pre Android (APK)
                </LinkButton>
            </DownloadLinks>
            <Note>Ak máte akékoľvek otázky alebo problémy, kontaktujte našu podporu.</Note>
        </DownloadContainer>
    );
};

export default DownloadPage;